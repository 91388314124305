import { Disposable } from '@SDK/core';

import { globalSettings } from '../global-settings';
import logger from '../logger/logger';

const getAppVersion = () => {
  const version = process.env.APP_VERSION || '1.0.0';

  if (process.env.IS_RELEASE_MODE === 'true') {
    return `${version}-prod-${globalSettings.vueVersion === 'vue3' ? 'modern' : 'legacy'}`;
  }

  return `${version}-stage-${globalSettings.vueVersion}`;
};

type EnvironmentVariableKey =
  | 'apiRCSocketURL'
  | 'isStrictMode'
  | 'debugApiBaseProdURL'
  | 'yandexMetricaId'
  | 'webAppURL'
  | 'dsmlPartnerId'
  | 'dsmlPassword'
  | 'appVersion'
  | 'apiBaseURL'
  | 'apiSecretKey'
  | 'isDev'
  | 'isRelease'
  | 'apiFlagsBaseURL';

const isDev = process.env.NODE_ENV === 'development';

const getEnv = (env: string) => {
  if (isDev) {
    return process.env[env];
  }

  return process.env[env];
};

export class EnvironmentService extends Disposable {
  private map: Record<EnvironmentVariableKey, any> = {
    debugApiBaseProdURL: 'https://api.viju.ru/',
    yandexMetricaId: '95696750',
    apiSecretKey: 'a2jCEwAWqLepW4A+V/4hMIRCr5PSb5bzoeLUEFtkm61Qa3JfdVVbacqJtvHPLJc6xdt3VMtHVL2zfbzcBWVSXA==',
    appVersion: () => getAppVersion(),
    isDev: process.env.NODE_ENV === 'development',
    apiRCSocketURL:
      (isDev ? getEnv('API_RC_SOCKET_URL') : process.env.API_RC_SOCKET_URL) ||
      'wss://centrifugo-ws.testvip.ru/connection/websocket',
    webAppURL: (isDev ? getEnv('WEB_APP_URL') : process.env.WEB_APP_URL) || 'https://viju.ru/',
    dsmlPartnerId:
      (isDev ? getEnv('DSML_PARTNER_ID') : process.env.DSML_PARTNER_ID) || '581e818b-0058-4fda-808c-d9c41c864640',
    dsmlPassword: (isDev ? getEnv('DSML_PASSWORD') : process.env.DSML_PASSWORD) || 'eJh2IP1DbwtUN#O<',
    apiBaseURL: (isDev ? getEnv('API_BASE_URL') : process.env.API_BASE_URL) || 'https://api.viju.ru/',
    isRelease: (isDev ? getEnv('IS_RELEASE_MODE') : process.env.IS_RELEASE_MODE) === 'true',
    isStrictMode: (isDev ? getEnv('IS_RELEASE_MODE') : process.env.IS_RELEASE_MODE) !== 'true',
    apiFlagsBaseURL: isDev ? getEnv('API_FLAGS_BASE_URL') : process.env.API_FLAGS_BASE_URL || 'https://flags.viju.ru/',
  };

  constructor() {
    super();
  }

  public init(vars: Partial<Record<EnvironmentVariableKey, any>>) {
    this.map = {
      ...this.map,
      ...vars,
    };
  }

  public getVariable<T>(key: EnvironmentVariableKey): T {
    if (Reflect.has(this.map, key)) {
      return this.map[key];
    }

    logger.warn(`EnvironmentService - Expect ${key} to be defined`);

    return this.map[key];
  }
}
