// @ts-check
import 'core-js/es/object/get-own-property-descriptors';
import 'core-js/es/object/assign';
import 'core-js/es/set';
import 'core-js/es/weak-map';
import 'core-js/es/promise';
import 'unfetch/polyfill';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import '@package/ui/src/styles/style.css';
import './styles/app.scss';
import '@package/ui/src/styles/custom-normalize.scss';
import 'normalize.css';

import {
  alertService,
  appNavigationService,
  authService,
  deviceService,
  environmentService,
  featuresService,
  globalSettings,
  i18n,
  initStore,
  logger,
  OperationSystem,
  RouterPage,
  routerService,
  useContentStore,
  useSessionStore,
} from '@package/sdk/src/smarttv';
import VueCompositionApi, { computed, inject, nextTick, onMounted, onUnmounted, ref } from '@vue/composition-api';
import Axios, { isCancel } from 'axios';
import cryptoJs from 'crypto-js';
import * as dateFns from 'date-fns';
import { ru } from 'date-fns/locale';
import JwtDecode from 'jwt-decode';
import PortalVue from 'portal-vue';
import * as QRCode from 'qrcode';
import { v4 as uuidv4 } from 'uuid';
import Vue from 'vue';
import VueRouter, { RouterLink } from 'vue-router';
import Vuex from 'vuex';

import NavigatableItem from '@/components/navigation/NavigatableItem.vue';

import { EnvironmentVariable } from '../config/helpers/env';
import App from './App.vue';
import routes from './pages/routes';

dateFns.setDefaultOptions({ locale: ru });

Vue.use(PortalVue);

globalSettings.axios = Axios;
globalSettings.axiosIsCancel = isCancel;
globalSettings.vueVersion = 'vue2';
globalSettings.computed = computed;
globalSettings.ref = ref;
globalSettings.onMounted = onMounted;
globalSettings.vueCompositionApi = VueCompositionApi;
globalSettings.vue = Vue;
globalSettings.vuex = Vuex;
globalSettings.router = VueRouter;
globalSettings.routes = routes;
globalSettings.nextTick = nextTick;
globalSettings.jwt = JwtDecode;
globalSettings.dateFns = dateFns;
globalSettings.qrcode = QRCode;
globalSettings.uuidv4 = uuidv4;
globalSettings.cryptoJs = cryptoJs;
globalSettings.inject = inject;
globalSettings.onUnmounted = onUnmounted;

Vue.use(VueCompositionApi);
Vue.use(VueRouter);

Vue.prototype.$RouterPage = RouterPage;
Vue.prototype.$Features = featuresService;

Vue.component('NavigatableItem', NavigatableItem);
Vue.component('RouterLink', RouterLink);

const store = initStore();

const createAppView = () => {
  return new Vue({
    router: routerService._router,
    store: store as any,
    render: (h) => h(App as any),
  }).$mount('#app');
};

const LOG_LEVEL = 6;

const setupLoggers = () => {
  logger.setLevel(LOG_LEVEL);
};

const showAppVersion = () => {
  console.info(
    '%c Viju smarttv version: ' + '%c '.concat(EnvironmentVariable.APP_VERSION || '', ' '),
    'color: #fff; background: #0A1E1F',
    'color: #fff; background: #cc6666',
  );
};

const registerTizenKeys = [
  'MediaPlay',
  'MediaPause',
  'MediaStop',
  'MediaRewind',
  'MediaFastForward',
  'ChannelUp',
  'ChannelDown',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];

async function main() {
  try {
    setupLoggers();

    showAppVersion();

    routerService.initialize();
    alertService.initialize();

    appNavigationService.init();

    // Инициализируем системные API
    await deviceService.init();
    // // активируем хуки авторизации / род. контроля.
    authService.init();
    Vue.use(i18n);

    const contentStore = useContentStore();
    const sessionStore = useSessionStore();

    const isDev = process.env.NODE_ENV === 'development';

    environmentService.init({
      isDev,
      webAppURL: (isDev ? EnvironmentVariable.WEB_APP_URL : process.env.WEB_APP_URL) || 'https://viju.ru/',
      dsmlPartnerId: isDev ? EnvironmentVariable.DSML_PARTNER_ID : process.env.DSML_PARTNER_ID,
      dsmlPassword: isDev ? EnvironmentVariable.DSML_PASSWORD : process.env.DSML_PASSWORD,
      apiBaseURL: (isDev ? EnvironmentVariable.API_BASE_URL : process.env.API_BASE_URL) || 'https://api.viju.ru/',
      isRelease: isDev ? EnvironmentVariable.IS_RELEASE_MODE : process.env.IS_RELEASE_MODE === 'true',
      isStrictMode: isDev ? EnvironmentVariable.IS_RELEASE_MODE : process.env.IS_RELEASE_MODE !== 'true',
      apiFlagsBaseURL: isDev
        ? EnvironmentVariable.API_FLAGS_BASE_URL
        : process.env.API_FLAGS_BASE_URL || 'https://flags.viju.ru/',
    });

    if (deviceService.os === OperationSystem.Tizen) {
      for (const key of registerTizenKeys) {
        // @ts-ignore
        window.tizen.tvinputdevice.registerKey(key);
      }
    }

    createAppView();

    await Promise.all([
      sessionStore.fetchUser({ forceFetchUser: true }),
      contentStore.fetchGenres(),
      sessionStore.fetchOffers(),
    ]);
  } catch (error) {
    console.error(error);

    // document.querySelector('#app').innerHTML = error;
  }
}

main();
