import apiBaseUrl from './api-base-url';
import apiRefreshTimeout from './api-refresh-timeout';
import apiSecretKey from './api-secret-key';
import appVersion from './app-version';
import dsmlParentId from './dsml-parent-id';
import dsmlPassword from './dsml-password';
import isDev from './is-dev';
import outputPath from './output-path';
import port from './port';
import publicPath from './public-path';
import webAppUrl from './web-app-url';

export const EnvironmentVariable = {
  PORT: port,
  APP_VERSION: appVersion,
  OUTPUT_PATH: outputPath,
  PUBLIC_PATH: publicPath,
  IS_DEV: isDev,
  API_BASE_URL: apiBaseUrl,
  API_FLAGS_BASE_URL: '',
  API_SECRET_KEY: apiSecretKey,
  IS_RELEASE_MODE: false,
  // @deprecated
  API_REFRESH_TIMEOUT: Number(apiRefreshTimeout),
  DSML_PASSWORD: dsmlPassword,
  DSML_PARTNER_ID: dsmlParentId,
  WEB_APP_URL: webAppUrl,
};
