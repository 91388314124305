import AppEvent, { AppEventType } from '../../core/event/event';
import { EventEmitter } from '../../core/event/event-emitter';
import { DesktopKeyCode, TizenKeyCode, TvKeyCode, WebOSKeyCode } from '../../core/keyboard/tv-keys';
import type { IDisposable } from '../../core/lifecycle/disposable';
import { Disposable } from '../../core/lifecycle/disposable';
import { isUndefinedOrNull } from '../../core/std/types';
import { deviceService, telemetryService } from '../services';
import { OperationSystem } from '../services/device/device-types';
import logger from '../services/logger/logger';

export class AppKeyboardEvent extends AppEvent {
  constructor(public readonly domEvent: KeyboardEvent) {
    super();
  }

  public get type(): AppEventType {
    return 'keyboard';
  }

  public preventDefault(): void {
    this.domEvent.preventDefault();
    super.preventDefault();
  }

  public toString() {
    const { type, key, keyCode } = this.domEvent;

    return `
      KEYBOARD
      type: ${type},
      key: ${key},
      keyCode: ${keyCode}
    `;
  }
}

type KeyboardEventMap = Record<TvKeyCode, AppKeyboardEvent>;

class KeyboardEventHandler extends Disposable {
  private readonly emitter: EventEmitter<KeyboardEventMap> = new EventEmitter();

  constructor() {
    super();
    this.init();
  }

  private get keyCodes() {
    if (deviceService.os === OperationSystem.WebOs) {
      return WebOSKeyCode;
    }

    if (deviceService.os === OperationSystem.Tizen) {
      return TizenKeyCode;
    }

    return DesktopKeyCode;
  }

  public getTvKeyCode(keyCode: number): TvKeyCode | undefined {
    for (const [key, value] of this.keyCodes) {
      if (value === keyCode) {
        return key;
      }
    }

    return undefined;
  }

  public trigger<T extends keyof KeyboardEventMap>(event: T) {
    this.emitter.emit(event, new AppKeyboardEvent(new KeyboardEvent('keydown')));
  }

  public on<T extends keyof KeyboardEventMap>(event: T, listener: (arg: KeyboardEventMap[T]) => void): IDisposable {
    return this.emitter.on(event, listener);
  }

  private onKeydown = (event: KeyboardEvent): void => {
    const eventCode = this.getTvKeyCode(event.keyCode);

    logger.info('onKeyDown#event.keyCode', event.keyCode);

    if (isUndefinedOrNull(eventCode)) {
      return;
    }

    const appEvent = new AppKeyboardEvent(event);

    // трекаем событие
    telemetryService.trackUserEvent(appEvent);

    this.emitter.emit(eventCode, appEvent);
  };

  private init(): void {
    window.addEventListener('keydown', this.onKeydown);
  }

  public dispose() {
    window.removeEventListener('keydown', this.onKeydown);
    this.emitter.dispose();
  }
}

export const keyboardEventHandler = new KeyboardEventHandler();

window.$keyboardDebug = keyboardEventHandler;

declare global {
  interface Window {
    $keyboardDebug: unknown;
  }
}
