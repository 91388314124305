import { RouteLocationNormalized } from 'vue-router';

import { EventEmitter, UnexpectedComponentStateError } from '../../../core';
import { getStoreProperty, initStore, useSessionStore, VuexStoreNamespace } from '../../stores';
import { useJwtDecoder } from '../../utils/use-jwt-decoder';
import type { DeviceService } from '../device/device-service';
import { Endpoints } from '../endpoints';
import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';
import { RouterPage } from '../router/router';
import type { RouterService } from '../router/router-service';
import type { IStorageService } from '../storage/storage-service';
import { StorageKeys } from '../storage/storage-types';
import type { UserService } from '../user/user-service';
import { AuthError } from './auth-errors';
import type {
  CheckTokenRequest,
  CheckTokenResponse,
  CreateTokenRequest,
  CreateTokenResponse,
  LoginParams,
  LoginRequest,
  LoginResponse,
  SendOtpRequest,
  SendOtpResponse,
} from './auth-types';
import { isEmailLogin } from './string';

const authProtectedRoutes = [
  RouterPage.SettingsPage,
  RouterPage.PersonalData,
  RouterPage.Parental,
  RouterPage.ParentalCode,
  RouterPage.ParentalRating,
  RouterPage.ParentalCodeRecover,
  RouterPage.HelpContacts,
  RouterPage.UserSubscriptions,
  RouterPage.PartnerNoSubscriptionPage,
];

const nonAuthProtectedRoutes = [RouterPage.AuthPage];

const nonSubscriptionProtectedRoutes = [RouterPage.Offers, RouterPage.OfferInfo];

interface AuthServiceEventMap {
  'user.login': undefined;
  'user.logout': undefined;
}

export class AuthService {
  public readonly emitter: EventEmitter<AuthServiceEventMap> = new EventEmitter<AuthServiceEventMap>();

  constructor(
    private readonly requestService: RequestService,
    private readonly deviceService: DeviceService,
    private readonly userService: UserService,
    private readonly storageService: IStorageService,
    private readonly routerService: RouterService,
  ) {}

  private async post<T, R>(body: T, url: Endpoints) {
    return this.requestService.request<R, T>(
      {
        method: HTTPRequestMethod.Post,
        url,
        data: body,
      },
      { withSignature: true },
    );
  }

  private async saveAuthData(data: Partial<LoginResponse>) {
    this.storageService.setItem(StorageKeys.User, '');
    this.storageService.setItem(StorageKeys.Profile, '');
    this.storageService.setItem(StorageKeys.AccessToken, '');
    this.storageService.setItem(StorageKeys.RefreshToken, '');
    this.storageService.setItem(StorageKeys.Visitor, '');

    if (data.auth) {
      this.storageService.setItem(StorageKeys.AccessToken, data.auth.token);
      this.storageService.setItem(StorageKeys.RefreshToken, data.auth.refresh_token);
    }

    if (data.user) {
      const sessionStore = useSessionStore();

      this.storageService.setItem(StorageKeys.User, data.user);
      await sessionStore.fetchUser();

      this.emitter.emit('user.login');
    }
  }

  public get sessionId() {
    const jwtDecoder = useJwtDecoder();
    const token = this.storageService.getItem(StorageKeys.AccessToken);

    if (!token) {
      return '';
    }

    return jwtDecoder.decode(token as string)?.sub ?? '';
  }

  public abort(message = 'Cancelled by user'): void {
    this.requestService.abort(message);
  }

  public async signIn({ userLogin, password, otp }: LoginParams) {
    const isEmail = isEmailLogin(userLogin);

    try {
      const body = {
        password,
        otp,
        ...(!isEmail && { phone_number: userLogin }),
        ...(isEmail && { email: userLogin }),
        device: await this.deviceService.getDevice(),
      } as LoginRequest;
      const { data } = await this.post<LoginRequest, LoginResponse>(body, Endpoints.AuthSignIn);

      await this.saveAuthData(data);
      return data;
    } catch (e) {
      await this.saveAuthData({});
      throw new AuthError(e);
    }
  }

  public async signUp({ userLogin, password }: LoginParams) {
    const isEmail = isEmailLogin(userLogin);

    try {
      const body = {
        password,
        ...(!isEmail && { phone_number: userLogin }),
        ...(isEmail && { email: userLogin }),
        device: await this.deviceService.getDevice(),
      } as LoginRequest;
      const { data } = await this.post<LoginRequest, LoginResponse>(body, Endpoints.AuthSignUp);

      await this.saveAuthData(data);
      return data;
    } catch (e) {
      await this.saveAuthData({});
      throw new AuthError(e);
    }
  }

  public async signOut() {
    const store = initStore();
    const isAuth = store.getters[getStoreProperty(VuexStoreNamespace.Session, 'isAuth')];

    if (!isAuth) {
      throw new UnexpectedComponentStateError('isAuth');
    }

    try {
      await this.requestService.request(
        {
          url: Endpoints.AuthSessionId.replace(':id', this.sessionId),
          method: 'DELETE',
        },
        { withToken: true },
      );
    } catch (error) {
      // it is ok
    } finally {
      store.commit(getStoreProperty(VuexStoreNamespace.Session, '$patch'), {
        _user: undefined,
        _profile: undefined,
        _profiles: [],
      });

      await this.saveAuthData({});

      this.emitter.emit('user.logout');
    }
  }

  public async checkTvToken(token: string) {
    const body = {
      tv_token: token,
      device: await this.deviceService.getDevice(),
    } as CheckTokenRequest;

    const { data } = await this.post<CheckTokenRequest, CheckTokenResponse>(body, Endpoints.AuthCheckToken);

    await this.saveAuthData(data);
  }

  public async createTvToken() {
    try {
      const code = String(Math.floor(10000 + Math.random() * 90000));
      const body = {
        code,
        device: await this.deviceService.getDevice(),
      } as CreateTokenRequest;

      const { data } = await this.post<CreateTokenRequest, CreateTokenResponse>(body, Endpoints.AuthCreateToken);

      return {
        tvCode: code,
        tvToken: data.tv_token,
      };
    } catch (e) {
      throw new AuthError(e);
    }
  }

  public async sendOtp(userLogin: string) {
    const isEmail = isEmailLogin(userLogin);

    try {
      const body = {
        phone_number: !isEmail ? userLogin : undefined,
        email: isEmail ? userLogin : undefined,
      };
      const { data } = await this.post<SendOtpRequest, SendOtpResponse>(body, Endpoints.AuthSendOtp);

      return data;
    } catch (e) {
      throw new AuthError(e);
    }
  }

  public init() {
    const onSubscriptionCheckHook = (to: RouteLocationNormalized, _: RouteLocationNormalized, next: VoidFunction) => {
      const store = initStore();

      const isAuth = store.getters[getStoreProperty(VuexStoreNamespace.Session, 'isAuth')];
      const isActiveSubscription = store.getters[getStoreProperty(VuexStoreNamespace.Session, 'isActiveSubscription')];

      if (!isAuth && next) {
        return next();
      }

      if (isActiveSubscription && nonSubscriptionProtectedRoutes.includes(to.name as RouterPage)) {
        return this.routerService.replace({ name: RouterPage.MainPage });
      }

      if (next) {
        return next();
      }
    };

    const onNonAuthCheckHook = (to: RouteLocationNormalized, _: RouteLocationNormalized, next: VoidFunction) => {
      const store = initStore();
      const isAuth = store.getters[getStoreProperty(VuexStoreNamespace.Session, 'isAuth')];

      if (!isAuth && authProtectedRoutes.includes(to.name as RouterPage)) {
        return this.routerService.replace({ name: RouterPage.AuthPage });
      }

      if (next) {
        return next();
      }
    };

    const onAuthCheckHook = (to: RouteLocationNormalized, _: RouteLocationNormalized, next: VoidFunction) => {
      const store = initStore();
      const isAuth = store.getters[getStoreProperty(VuexStoreNamespace.Session, 'isAuth')];

      if (!isAuth && next) {
        return next();
      }

      if (nonAuthProtectedRoutes.includes(to.name as RouterPage)) {
        return this.routerService.replace({ name: RouterPage.MainPage });
      }

      if (next) {
        next();
      }
    };

    this.routerService.addBeforeResolve(onAuthCheckHook);
    this.routerService.addBeforeResolve(onSubscriptionCheckHook);
    this.routerService.addBeforeResolve(onNonAuthCheckHook);
  }
}
