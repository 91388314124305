import { ApplicationError } from '../../core';

type AxiosError = any;
type HttpStatusCode = any;

export class ApiError extends ApplicationError {
  public status?: HttpStatusCode;

  public readonly name = 'ApiError';

  constructor(error: AxiosError) {
    super();
    this.status = error?.response?.status;
  }

  public is(status: HttpStatusCode) {
    return this.status === status;
  }

  public toJSON(): Record<string, any> {
    return {
      status: this.status,
    };
  }
}
