import { MediaContentType } from '../../api/content/types/content-type';
import { isDefined } from '../../core/std/types';
import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { getAnalyticContentTypeValue } from './get-kmza-content-type-value';
import { GAValue } from './kmza';
import { ItemPageOptions } from './use-content-page-kmza';

const getPayload = ({
  title,
  contentType,
  season,
  episode,
  from,
  playlistTitle,
  kinomTitle,
}: ItemPageOptions): GAValue[] => {
  let payload: GAValue[] = [
    {
      label: AnalyticPayloadLabelName.ItemTitle,
      value: title,
    },
    {
      label: AnalyticPayloadLabelName.ItemType,
      value: getAnalyticContentTypeValue(contentType),
    },
  ];

  if (contentType !== MediaContentType.Movie) {
    payload = [
      ...payload,
      {
        label: AnalyticPayloadLabelName.Season,
        value: season || 0,
      },
      {
        label: AnalyticPayloadLabelName.Episode,
        value: episode || 0,
      },
    ];
  }

  if (isDefined(kinomTitle)) {
    payload.push({ label: AnalyticPayloadLabelName.KinomTitle, value: kinomTitle });
  }

  if (from) {
    payload.push({
      label: AnalyticPayloadLabelName.From,
      value: from,
    });
  }

  if (playlistTitle) {
    payload.push({
      label: AnalyticPayloadLabelName.PlaylistTitle,
      value: playlistTitle,
    });
  }

  return payload;
};

export function usePlayerPageAnalytics() {
  const onGotoPlayerPage = (options: ItemPageOptions) => {
    window.$dsmlService.sendAnalyticEvent({
      name: AnalyticEventNameNew.GotoPlayerPage,
      values: getPayload(options),
      options: { itemId: options.itemId, kinomId: options.kinomId, episodeId: options.episodeId },
    });
  };

  const onShowPlayerPage = (options: ItemPageOptions) => {
    window.$dsmlService.sendAnalyticEvent({
      name: AnalyticEventNameNew.ShowPlayerPage,
      values: getPayload(options),
      options: { itemId: options.itemId, kinomId: options.kinomId, episodeId: options.episodeId },
    });
  };

  return {
    onGotoPlayerPage,
    onShowPlayerPage,
  };
}
