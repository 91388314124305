// @ts-nocheck

import { globalSettings } from '../../services';
import { InitializedModuleStore, initStore } from '../index';
import { VuexStoreNamespace } from './namespaces';

type StoreModule<State, Getters, Actions> = {
  state: () => State;
  getters?: Record<string, (state: State, getters?: Getters) => any>;
  mutations?: Record<string, (state: State, params: any) => any>;
  actions?: Record<string, ($store: InitializedModuleStore<State, Getters, Actions>, params: any) => any>;
};

export const defineStore =
  <State, Getters, Actions, ModuleName extends VuexStoreNamespace>(
    moduleName: ModuleName,
    module: StoreModule<State, Getters, Actions>,
  ) =>
  (computed: Function = globalSettings.computed): InitializedModuleStore<State, Getters, Actions, ModuleName> => {
    const store = initStore();

    const newModule = {
      ...module,
      namespaced: true,
      mutations: {
        $patch: (state: Partial<State>, props: Partial<State>) => {
          Object.keys(props).forEach((prop) => {
            state[prop as keyof State] = props[prop as keyof State];
          });
        },
      },
    };

    if (!store.state[moduleName]) {
      store.registerModule(moduleName, newModule);
    }

    return {
      ...Object.entries(store.state[moduleName]).reduce(
        (result, [key, value]) => ({
          ...result,
          [key]: computed ? computed(() => store.state[moduleName][key]) : value,
        }),
        {},
      ),

      ...Object.entries(store.getters)
        .filter(([key]) => key.includes(moduleName))
        .reduce(
          (result, [key, value]) => ({
            ...result,
            [key.split('/')[1]]: computed ? computed(() => store.getters[key]) : value,
          }),
          {},
        ),

      ...Object.entries(newModule.actions || {}).reduce(
        (result, [name, fn]) => ({
          ...result,
          [name]: (...params: any) => fn(store, ...params),
        }),
        {},
      ),
      ...Object.entries(newModule.mutations).reduce(
        (result, [name, fn]) => ({
          ...result,
          [name]: (params: any) => fn(store.state[moduleName], params),
        }),
        {},
      ),
    };
  };
