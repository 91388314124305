import { Disposable } from '../../../core/lifecycle/disposable';

export abstract class AbstractManufacturerService extends Disposable {
  public abstract getDeviceInfo(): unknown;

  public abstract getManufacturerName(): string;

  public abstract getHumanReadableDeviceName(): string;

  public abstract init(): Promise<void>;

  public abstract isWeakDevice(): boolean;
}
