import { useAccountPageAnalytics, useSubscribeAnalytics } from '../../../../analytics';
import { isUndefinedOrNull } from '../../../../core';
import { useAuthStore } from '../../../stores/use-auth-store';
import { useSessionStore } from '../../../stores/use-session-store';
import { storeToRefs } from '../../../stores/utils/store-to-refs';
import { analyticService, routerService } from '../../index';
import logger from '../../logger/logger';
import { RouterPage } from '../../router/router';

export function useOfferActions() {
  const authStore = useAuthStore();
  const subscribePageAnalytics = useSubscribeAnalytics();
  const accountPageAnalytics = useAccountPageAnalytics();

  const { isPartnerUser, isActiveSubscription } = storeToRefs<any, any, any>(useSessionStore());
  const { selectedOfferId, backUrl } = storeToRefs<any, any, any>(authStore);

  const openOffersPage = () => {
    if (isPartnerUser.value && !isActiveSubscription.value) {
      accountPageAnalytics.onGotoAccountPage(analyticService.getAnalyticPageName());

      return routerService.push(!isUndefinedOrNull(backUrl.value) ? backUrl.value : { name: RouterPage.MainPage });
    }

    if (!backUrl.value) {
      authStore.setBackUrl(routerService.fullPath);
    }

    logger.info('openOffersPage#-backUrl', backUrl.value);

    subscribePageAnalytics.onShowSubscribeOfferPage('default');
    return routerService.push({ name: RouterPage.Offers });
  };

  const openSelectedOfferPage = () => {
    if (!backUrl.value) {
      authStore.setBackUrl(routerService.fullPath);
    }

    subscribePageAnalytics.onClickSubscribeOfferChoose(selectedOfferId.value);
    return routerService.push({ name: RouterPage.OfferInfo, params: { offerId: selectedOfferId.value } });
  };

  return {
    openSelectedOfferPage,
    openOffersPage,
  };
}
