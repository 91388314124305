import { Channel } from '../../api/channels/types/channel';
import { Media } from '../../api/content/types/media';
import { Moment } from '../../api/content/types/moment';
import { Movie } from '../../api/content/types/movie';
import { Serial } from '../../api/content/types/serial';
import { defineStore } from './utils/define-store';
import { getStoreProperty, VuexStoreNamespace } from './utils/namespaces';

type SelectedItem = { item: Movie | Serial | Media | Moment | Channel | null; rowId: number };

export interface CatalogState {
  _selectedItem: SelectedItem | null;
  _isUpdated: boolean;
}

interface CatalogActionWithoutStoreArgument {
  updateSelectedItem(props: { value: SelectedItem | undefined; rowId?: number }): void;

  setUpdated(value: boolean): void;
}

export interface CatalogGetters {
  readonly selectedItem: SelectedItem;
  readonly isUpdated: boolean;
}

export const useCatalogStore = defineStore<
  CatalogState,
  CatalogGetters,
  CatalogActionWithoutStoreArgument,
  VuexStoreNamespace.Catalog
>(VuexStoreNamespace.Catalog, {
  state: (): CatalogState => ({
    _selectedItem: null,
    _isUpdated: false,
  }),
  getters: {
    selectedItem: (state) => state._selectedItem,
    isUpdated: (state) => state._isUpdated,
  },
  actions: {
    updateSelectedItem(
      store,
      props: {
        value: SelectedItem;
        rowId?: number;
      },
    ): void {
      const { value, rowId } = props || {};

      if (!value) {
        store.commit(getStoreProperty(VuexStoreNamespace.Catalog, '$patch'), { _selectedItem: null });
        return;
      }

      store.commit(getStoreProperty(VuexStoreNamespace.Catalog, '$patch'), {
        _selectedItem: {
          item: value,
          rowId: rowId!,
        },
      });
    },
    setUpdated(store, value: boolean) {
      store.commit(getStoreProperty(VuexStoreNamespace.Catalog, '$patch'), { _isUpdated: value });
    },
  },
});
