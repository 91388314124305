import { Disposable, EventEmitter } from '@SDK/core';
import { environmentService } from '@SDK/smarttv';

interface LifecycleEventMap {
  'will-shutdown': void;
}

export class LifecycleService extends Disposable {
  private emitter: EventEmitter<LifecycleEventMap> = new EventEmitter<LifecycleEventMap>();

  constructor() {
    super();

    this.registerListeners();
  }

  public onWillShutdown(callback: VoidFunction) {
    return this.emitter.on('will-shutdown', callback);
  }

  private registerListeners() {
    window.addEventListener('beforeunload', (event) => {
      const isDev = environmentService.getVariable('isDev');

      if (isDev) {
        return;
      }

      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;

      this.emitter.emit('will-shutdown');
    });
  }
}
