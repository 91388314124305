<template>
  <portal :to="modalTarget" :disabled="disabled">
    <div :class="$style.modal">
      <slot />
    </div>
  </portal>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    modalTarget: {
      type: String,
      default: 'alerts',
    },
  },
};
</script>

<style module lang="scss">
.modal {
  position: relative;
}
</style>
