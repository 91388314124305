import { useAuthPageAnalytics } from '../../../../analytics';
import { useAuthStore } from '../../../stores/use-auth-store';
import { useSessionStore } from '../../../stores/use-session-store';
import { storeToRefs } from '../../../stores/utils/store-to-refs';
import { analyticService, globalSettings, routerService } from '../../index';
import { RouterPage } from '../../router/router';

type RouteLocationRaw = any;

interface IRedirectUserOptions {
  replace: boolean;
}

export function useAuthActions() {
  const authStore = useAuthStore();
  const sessionStore = useSessionStore();
  const authPageAnalytics = useAuthPageAnalytics();

  const { isPartnerUser, isActiveSubscription } = storeToRefs<any, any, any>(sessionStore);
  const { backUrl, selectedOfferId } = storeToRefs<any, any, any>(authStore);

  // Пока так
  const isBackUrlToOffers = globalSettings.computed(() => backUrl.value.includes('offer'));

  const redirectUserWithBackUrl = (route: RouteLocationRaw, options?: IRedirectUserOptions) => {
    if (options?.replace) {
      return routerService.replace(route);
    }

    return routerService.push(route);
  };

  const openAuthPage = () => {
    if (!backUrl.value) {
      authStore.setBackUrl(routerService.fullPath);
    }

    authPageAnalytics.onGotoAuthPage(analyticService.getAnalyticPageName());

    return routerService.push({ name: RouterPage.AuthPage });
  };

  const getBackRedirectUrl = (): RouteLocationRaw => {
    if (!backUrl.value) {
      return { name: RouterPage.ProfilesPage };
    }

    return backUrl.value;
  };

  const getRedirectRouteAfterChoosingProfile = () => {
    if (backUrl.value) {
      return getBackRedirectUrl();
    }

    return { name: RouterPage.MainPage };
  };

  const getRedirectRouterAfterSuccessfulSubscription = () => {
    return { name: RouterPage.ProfilesPage };
  };

  const getRedirectRouterAfterAuthorization = (): RouteLocationRaw => {
    if (isActiveSubscription.value) {
      return { name: RouterPage.ProfilesPage };
    }

    if (!isPartnerUser.value) {
      if (selectedOfferId.value) {
        return { name: RouterPage.OfferInfo, params: { offerId: selectedOfferId.value } };
      }

      return getBackRedirectUrl();
    }

    return isBackUrlToOffers.value
      ? { name: RouterPage.MainPage, query: routerService._router.query }
      : getBackRedirectUrl();
  };

  return {
    openAuthPage,
    redirectUserWithBackUrl,
    getRedirectRouterAfterAuthorization,
    getRedirectRouteAfterChoosingProfile,
    getRedirectRouterAfterSuccessfulSubscription,
  };
}
