import IS_RELEASE_MODE from '../env/is-release-mode';
import ConfigError from './config-error';

export default function validateEnv(envVariable: string, envValue: unknown) {
  if (!IS_RELEASE_MODE) {
    return;
  }

  if (envValue === undefined) {
    // throw new ConfigError(envVariable);
  }
}
