import { Subscription } from '../../api';
import { Offer } from '../../api/offers/types/offer';
import { Profile } from '../../api/profiles/profiles';
import { SubscriptionPlatform } from '../../api/subscriptions/types/subscription-platform';
import { User } from '../../api/users/types/session-info';
import { UserType } from '../../api/users/types/user-type';
import { analyticService, deviceService, offersService, userService } from '../services';
import { defineStore } from './utils/define-store';
import { getStoreProperty, VuexStoreNamespace } from './utils/namespaces';

export interface SessionState {
  _user?: User;
  _profiles: Profile[];
  _profile?: Profile;
  _offers: Offer[];
}

export interface SessionGetters {
  currentOffer: Offer;
  hasTrialOffer: boolean;
  offers: Offer[];
  isActiveSubscription: boolean;
  isPartnerSubscription: boolean;
  isPartnerUser: boolean;
  subscription: Subscription;
  isAuth: boolean;
  user: User;
  profile: Profile;
  profiles: Profile[];
}

export interface SessionActionsWithoutStoreArgument {
  fetchUser(options?: FetchOptions): Promise<User | undefined>;

  setUserProfile(profile: Profile): void;

  fetchOffers(): Promise<void>;

  reset(): void;
}

interface FetchOptions {
  forceFetchUser?: boolean;
}

export const useSessionStore = defineStore<
  SessionState,
  SessionGetters,
  SessionActionsWithoutStoreArgument,
  VuexStoreNamespace.Session
>(VuexStoreNamespace.Session, {
  state: (): SessionState => ({
    _offers: [],
    _user: undefined,
    _profiles: [] as Profile[],
    _profile: undefined,
  }),
  getters: {
    currentOffer: (state) => {
      let offer = state._offers.find(
        (offer) => offer.platform === (state._user?.subscription?.platform || SubscriptionPlatform.Cloudpayments),
      );

      if (!offer) {
        offer = state._offers.find((offer) => offer.platform === SubscriptionPlatform.Payture);
      }

      return offer;
    },
    hasTrialOffer: (state, getters: any) => {
      return Boolean(state._offers.find((offer) => offer.platform === getters.currentOffer?.platform)?.trialAvailable);
    },
    offers: (state) => state._offers,
    isActiveSubscription: (state) => Boolean(state._user?.subscription?.active),
    isPartnerSubscription: (state) => state._user?.subscription?.platform === SubscriptionPlatform.Partner,
    isPartnerUser: (state) => state._user?.userType === UserType.Partner,
    subscription: (state) => state._user?.subscription,
    isAuth: (state) => Boolean(state._user),
    user: (state) => state._user,
    profile: (state) => state._profile,
    profiles: (state) => state._profiles,
  },
  actions: {
    async fetchUser(store, options: FetchOptions = {}): Promise<User | undefined> {
      const { forceFetchUser = false } = options;

      let user = store.state[VuexStoreNamespace.Session]._user as User;
      const profile = store.state[VuexStoreNamespace.Session]._profile as Profile;

      if (forceFetchUser || !user) {
        user = (await userService.fetchUser()) as User;

        store.commit(getStoreProperty(VuexStoreNamespace.Session, '$patch'), { _user: user });
      }

      if (!user) {
        return undefined;
      }

      store.commit(getStoreProperty(VuexStoreNamespace.Session, '$patch'), { _profiles: user.profiles || [] });

      if (!profile) {
        const matchedProfile = user.profiles?.find((profile: Profile) => profile.id === user.currentProfileId);

        store.commit(getStoreProperty(VuexStoreNamespace.Session, '$patch'), { _profile: matchedProfile });
      }

      analyticService.setUser({
        visitorId: deviceService.getVisitorId(),
        profileId: user.currentProfileId,
      });

      return user;
    },
    setUserProfile(store, profile: Profile): void {
      store.commit(getStoreProperty(VuexStoreNamespace.Session, '$patch'), { _profile: profile });
    },
    async fetchOffers(store) {
      store.commit(getStoreProperty(VuexStoreNamespace.Session, '$patch'), {
        _offers: await offersService.fetchOffers(),
      });
    },
    reset(store) {
      store.commit(getStoreProperty(VuexStoreNamespace.Session, '$patch'), {
        _user: undefined,
        _profile: undefined,
        _profiles: [],
      });
    },
  },
});
