import { defineStore } from './utils/define-store';
import { getStoreProperty, VuexStoreNamespace } from './utils/namespaces';

export interface AuthState {
  selectedOfferId: string;
  backUrl: string;
}

interface AuthActionsWithoutStoreArgument {
  setSelectedOfferId(offerId: string): void;

  setBackUrl(url: string): void;
}

export const useAuthStore = defineStore<AuthState, {}, AuthActionsWithoutStoreArgument, VuexStoreNamespace.Auth>(
  VuexStoreNamespace.Auth,
  {
    state: (): AuthState => ({
      selectedOfferId: '',
      backUrl: '',
    }),
    actions: {
      setSelectedOfferId(store, offerId: string) {
        store.commit(getStoreProperty(VuexStoreNamespace.Auth, '$patch'), { selectedOfferId: offerId });
      },
      setBackUrl(store, url: string) {
        store.commit(getStoreProperty(VuexStoreNamespace.Auth, '$patch'), { backUrl: url });
      },
    },
  },
);
