import { ApiMovie } from '../../../api/content/content-types/movie';
import { Movie } from '../../../api/content/types/movie';
import { MovieMapper } from '../../../api/movie/movie';
import { QuizGenreItemMapper } from '../../../api/quiz/quiz-genre-item';
import { ApiQuizGenreItem } from '../../../api/quiz/quiz-genre-item-types';
import { QuizGenreItem } from '../../../api/quiz/types/quiz-genre-item';
import { Endpoints } from '../endpoints';
import type { RequestService } from '../request-service';
import { HTTPRequestMethod } from '../request-service';

export class QuizService {
  constructor(private readonly $http: RequestService) {}

  public abort(message = 'Cancelled by user'): void {
    this.$http.abort(message);
  }

  public async fetchGenres(): Promise<QuizGenreItem[]> {
    const { data } = await this.$http.request<ApiQuizGenreItem[]>({
      method: HTTPRequestMethod.Get,
      url: Endpoints.QuizGenres,
    });

    return QuizGenreItemMapper.mapMany(data);
  }

  public async fetchGenresContents(ids: string[]): Promise<Movie[]> {
    const { data } = await this.$http.request<ApiMovie[]>({
      method: HTTPRequestMethod.Get,
      url: Endpoints.QuizGenresContents,
      params: {
        quiz_genres_ids: ids,
      },
    });

    return MovieMapper.mapMany(data);
  }
}
