// @ts-nocheck

import { isUndefinedOrNull } from '../../../core/std/types';
import logger from '../logger/logger';
import { AbstractManufacturerService } from './abstract-manufacturer-service';

export namespace TizenOS {
  export interface ApplicationInstance {
    exit(): void;
    appInfo: {
      id: string;
    };
  }

  export interface TVInputDeviceService {
    registerKey(key: string): void;
  }

  export interface ApplicationService {
    getCurrentApplication(): ApplicationInstance;
  }

  export interface NetworkService {
    isConnectedToGateway(): boolean;
  }

  export interface Instance {
    tvinputdevice: TVInputDeviceService;
    application: ApplicationService;
    network: NetworkService;
  }
}

declare global {
  const tizen: TizenOS.Instance;

  interface Window {
    tizen: TizenOS.Instance;
  }
}

const VENDOR_NAME = 'Samsung';
const DEFAULT_DEVICE_NAME = `${VENDOR_NAME}`;
const DEFAULT_APP_ID = 'samsung.unknown.appId';

const getSafeHumanReadableModelName = () => {
  if (isUndefinedOrNull(window.webapis)) {
    return DEFAULT_DEVICE_NAME;
  }

  try {
    const realModelName = window.webapis.productinfo.getRealModel();
    const realModelCode = window.webapis.productinfo.getModelCode();

    if (realModelName) {
      return `${VENDOR_NAME} ${realModelName} ${realModelCode}`;
    }
  } catch (error) {
    logger.error(error);

    return DEFAULT_DEVICE_NAME;
  }

  return DEFAULT_DEVICE_NAME;
};

const getSafeApplicationId = () => {
  try {
    const id = window.tizen.application.getCurrentApplication().appInfo.id;

    if (id) {
      return id;
    }

    return DEFAULT_APP_ID;
  } catch (error) {
    return DEFAULT_APP_ID;
  }
};

export class TizenService extends AbstractManufacturerService {
  constructor() {
    super();
  }

  public isWeakDevice(): boolean {
    return false;
  }

  public getHumanReadableDeviceName(): string {
    return getSafeHumanReadableModelName();
  }

  public getManufacturerName(): string {
    return VENDOR_NAME;
  }

  public getDeviceInfo(): unknown {
    return {
      appId: getSafeApplicationId(),
      modelName: this.getHumanReadableDeviceName(),
    };
  }

  public init() {
    return Promise.resolve(undefined);
  }
}
