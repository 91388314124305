<script>
import { appNavigationService } from '@package/sdk/src/smarttv';

export default {
  props: {
    group: { default: '' },
    tag: { default: 'div' },
    activeClass: { default: '' },
    loop: { default: '' },
  },
  data: () => ({
    isActive: false,
    item: undefined,
  }),
  computed: {
    classNames() {
      return {
        [this.$style.navigatable]: true,
        [this.$style.active]: !this.activeClass && this.isActive,
        [this.activeClass]: this.activeClass && this.isActive,
      };
    },
    activeNavigationItem() {
      return appNavigationService.activeNavigationItem.value;
    },
    element() {
      return this.$refs.item.$el || this.$refs.item;
    },
  },
  watch: {
    activeNavigationItem(value) {
      if (!value) {
        return;
      }

      this.setActiveCurrentItem(value === this.element);
    },
  },
  methods: {
    setActiveCurrentItem(active) {
      if (this.isActive && !active) {
        this.$emit('inactive', this.element);
      }

      this.isActive = active;

      if (!active) {
        return;
      }

      this.$emit('active', this.element);
    },
  },
  render(h) {
    return h(this.tag, {
      class: this.classNames,
      ref: 'item',
      attrs: {
        'data-navigatable': this.group,
        'data-loop': this.loop,
        'data-navigatable-active': this.isActive,
        ...this.$attrs,
      },
      scopedSlots: Object.keys(this.$slots).reduce((slots, key) => {
        slots[key] = () => this.$slots[key];

        return slots;
      }, {}),
    });
  },
};
</script>

<style module lang="scss">
@import '@/styles/mixins';
@import '@/styles/colors';

.navigatable {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: inherit;
    content: '';
  }

  &.active::after {
    box-shadow: inset 0 0 0 adjustPx(7px) map-get($map: $colors, $key: --c-hover-color);
  }
}

.highlight {
  box-shadow: inset 0 0 0 2px map-get($map: $colors, $key: --c-info-color);

  &::after {
    box-shadow: inset 0 0 0 2px map-get($map: $colors, $key: --c-info-color);
  }
}
</style>
