var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.wrapper},[(!_vm.isMenuHidden)?_c('AppMenu'):_vm._e(),(_vm.isBuildBadgeShown)?_c('AppBuildBadge'):_vm._e(),_c('RouterView',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var Component = ref.Component;
return [_c('KeepAlive',{key:_vm.cacheKey,attrs:{"include":[
        _vm.$RouterPage.CatalogPage,
        _vm.$RouterPage.SearchPage,
        _vm.$RouterPage.ChannelsPage,
        _vm.$RouterPage.MediaCardSimilarPage,
        _vm.$RouterPage.MyChannelPage ],"max":1}},[_c(Component,{tag:"component"})],1)]}}])}),_c('UIAlert'),_c('portal-target',{attrs:{"name":"alerts"}}),_c('portal-target',{attrs:{"name":"modals"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }