import { AnalyticPageName } from '../../../../analytics';
import { ItemPageFrom, useContentPageAnalytics } from '../../../../analytics/composables/use-content-page-kmza';
import { usePlayerPageAnalytics } from '../../../../analytics/composables/use-player-page-kmza';
import { MediaContentType } from '../../../../api/content/types/content-type';
import { analyticService, routerService } from '../../index';
import { RouterPage } from '../../router/router';

interface IOpenContentPageOptions {
  contentType: MediaContentType;
  id: string;
  from?: ItemPageFrom;
  title: string;
  seasonNumber?: number;
  episodeNumber?: number;
  searchText?: string;
  numberInResults?: number;
  titlesSelectionName?: string;
  titlesSelectionPosition?: number;
  startOffset?: number;
  playlistTitle?: string;
  episodeId?: string;
  kinomTitle?: string;
  kinomId?: string;
}

interface IOpenPlayerPageOptions {
  contentType: MediaContentType;
  title?: string;
  episodeId?: string;
  id: string;
  from?: ItemPageFrom;
  kinomId?: string;
  playlistTitle?: string;
  serialId?: string;
  kinomTitle?: string;
  seasonIndex?: string;
  episodeIndex?: string;
}

export function useMediaContentActions() {
  const contentPageAnalytics = useContentPageAnalytics();
  const playerPageAnalytics = usePlayerPageAnalytics();

  const openContentPage = (options: IOpenContentPageOptions) => {
    const {
      contentType,
      id,
      from,
      title,
      seasonNumber,
      episodeNumber,
      searchText,
      numberInResults,
      titlesSelectionName,
      titlesSelectionPosition,
      startOffset,
      playlistTitle,
      kinomId,
      episodeId,
      kinomTitle,
    } = options;

    switch (from) {
      case ItemPageFrom.ContinueWatch: {
        contentPageAnalytics.onGotoItemPage({
          contentType,
          itemId: id,
          kinomTitle,
          from: ItemPageFrom.ContinueWatch,
          title,
          kinomId,
          episodeId,
          page: AnalyticPageName.Main,
        });
        break;
      }
      case ItemPageFrom.Promo: {
        contentPageAnalytics.onGotoItemPageFromPromoSlider({
          contentId: id,
          contentType,
          contentTitle: title,
          id: kinomId as string,
          seasonNumber,
          episodeNumber,
          startOffset: startOffset as number,
        });
        break;
      }
      case ItemPageFrom.Dsml: {
        contentPageAnalytics.onGotoItemPage({
          contentType,
          itemId: id,
          episodeId,
          kinomId,
          episode: episodeNumber,
          season: seasonNumber,
          kinomTitle,
          from: ItemPageFrom.Dsml,
          title,
          page: AnalyticPageName.Main,
        });
        break;
      }
      case ItemPageFrom.Playlist: {
        contentPageAnalytics.onGotoItemPage({
          contentType,
          itemId: id,
          episode: episodeNumber,
          season: seasonNumber,
          episodeId,
          kinomId,
          from: ItemPageFrom.Playlist,
          title,
          playlistTitle,
          kinomTitle,
          page: AnalyticPageName.Main,
        });
        break;
      }
      case ItemPageFrom.Search: {
        contentPageAnalytics.onGotoItemPageFromSearch({
          title,
          contentType,
          searchText,
          episode: episodeNumber,
          season: seasonNumber,
          episodeId,
          kinomId,
          kinomTitle,
          numberInResults,
          itemId: id,
          page: AnalyticPageName.Search,
        });
        break;
      }
      case ItemPageFrom.Compilation: {
        contentPageAnalytics.onGotoItemPageFromCompilation({
          itemId: id,
          episodeId,
          kinomId,
          page: analyticService.getAnalyticPageName(),
          titlesSelectionName: titlesSelectionName as string,
          titlesSelectionPosition: titlesSelectionPosition as number,
        });

        break;
      }
      default: {
        contentPageAnalytics.onGotoItemPage({
          itemId: id,
          title,
          episode: episodeNumber,
          season: seasonNumber,
          kinomId,
          kinomTitle,
          episodeId,
          page: analyticService.getAnalyticPageName(),
          contentType,
        });
        break;
      }
    }

    return routerService.push({ name: RouterPage.MediaCardPage, params: { type: contentType, id } });
  };

  const openPlayerPage = (options: IOpenPlayerPageOptions) => {
    const {
      contentType,
      id,
      serialId,
      episodeIndex,
      seasonIndex,
      kinomTitle,
      kinomId,
      playlistTitle,
      from,
      episodeId,
      title,
    } = options;

    playerPageAnalytics.onGotoPlayerPage({
      contentType,
      itemId: serialId || id,
      episodeId,
      kinomTitle,
      playlistTitle,
      from,
      season: Number(seasonIndex),
      episode: Number(episodeIndex),
      kinomId,
      title: title as string,
    });

    return routerService.push({
      name: RouterPage.MediaCardPlayerPage,
      params: {
        id,
        type: contentType,
      },
      query: {
        seasonIndex,
        episodeIndex,
        episodeId,
      },
    });
  };

  const getPlayerPage = (options: IOpenPlayerPageOptions) => {
    const { contentType, id, serialId, episodeIndex, seasonIndex, episodeId } = options;

    let url = `/media-card/player/${id}/${contentType}/?`;

    if (seasonIndex) {
      url = `${url}seasonIndex=${seasonIndex}&`;
    }

    if (episodeIndex) {
      url = `${url}episodeIndex=${episodeIndex}&`;
    }

    if (serialId) {
      url = `${url}serialId=${serialId}&`;
    }

    if (episodeId) {
      url = `${url}episodeId=${episodeId}`;
    }

    return url;
  };

  return {
    openPlayerPage,
    openContentPage,
    getPlayerPage,
  };
}
