export interface Device {
  uid?: string | null;
  type?: 'tv';
  os?: string;
  name?: string;
  app_version?: string;
  model?: string;
  manufacturer?: string;
}

export enum OperationSystem {
  Desktop = 'Desktop',
  Mobile = 'Mobile',
  Tizen = 'TizenOS',
  WebOs = 'WebOS',
  AndroidTV = 'AndroidTV',
  Vidaa = 'VidaaOS',
}
