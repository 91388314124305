<template>
  <div :class="$style.wrapper">
    <AppMenu v-if="!isMenuHidden" />
    <AppBuildBadge v-if="isBuildBadgeShown" />
    <RouterView v-slot="{ Component }">
      <KeepAlive
        :key="cacheKey"
        :include="[
          $RouterPage.CatalogPage,
          $RouterPage.SearchPage,
          $RouterPage.ChannelsPage,
          $RouterPage.MediaCardSimilarPage,
          $RouterPage.MyChannelPage,
        ]"
        :max="1"
      >
        <component :is="Component" />
      </KeepAlive>
    </RouterView>
    <UIAlert />
    <portal-target name="alerts"></portal-target>
    <portal-target name="modals"></portal-target>
  </div>
</template>

<script>
import { isDefined } from '@package/sdk/src/core';
import {
  channelsService,
  contentCacheManager,
  RouterPage,
  routerService,
  storeToRefs,
  useContentStore,
  useSessionStore,
  useTvChannelsStore,
} from '@package/sdk/src/smarttv';
import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import { v4 as uuidv4 } from 'uuid';

import AppBuildBadge from '@/components/app-build-badge/AppBuildBadge.vue';
import AppMenu from '@/components/menu/AppMenu.vue';

import UIAlert from './components/alert/UIAlert.vue';

export default defineComponent({
  components: {
    AppMenu,
    AppBuildBadge,
    UIAlert,
  },
  setup(_, context) {
    const contentStore = useContentStore();
    const sessionStore = useSessionStore();
    const tvChannelsStore = useTvChannelsStore();

    const route = computed(() => context.root?.$route);

    const { profile } = storeToRefs(sessionStore);

    const isMenuHidden = ref(false);
    const cacheKey = ref(uuidv4());

    const isBuildBadgeShown = computed(
      () => route.value.path.includes('settings') || route.value.path.includes('auth'),
    );

    const hiddenAppMenuRoutes = [
      RouterPage.MediaCardPlayerPage,
      RouterPage.ChannelsPlayerPage,
      RouterPage.ProfilesPage,
      RouterPage.MediaCardSeriesPage,
      RouterPage.Offers,
    ];

    const cachedRoutes = [
      RouterPage.MediaCardControls,
      RouterPage.MediaCardDescription,
      RouterPage.MediaCardPersons,
      RouterPage.CatalogPage,
      RouterPage.CollectionPage,
      RouterPage.SearchPage,
      RouterPage.ChannelsPage,
      RouterPage.ChannelsPlayerPage,
      RouterPage.Offers,
      RouterPage.OfferInfo,
      RouterPage.MyChannelPage,
      RouterPage.AuthPage,
      RouterPage.MediaCardSimilarPage,
    ];

    const onDidProfileUpdated = () => {
      contentStore.reset();
      tvChannelsStore.reset();

      channelsService.fetchChannels();
      contentStore.fetchGenres();
      sessionStore.fetchOffers();
      contentCacheManager.clear();

      cacheKey.value = uuidv4();
    };

    const onBeforeEach = (to, from, next) => {
      if (isDefined(to.name) && hiddenAppMenuRoutes.includes(to.name)) {
        isMenuHidden.value = true;
        next();
        return;
      }

      if (isDefined(to.name) && isDefined(from.name) && !cachedRoutes.includes(to.name)) {
        cacheKey.value = uuidv4();
      }

      isMenuHidden.value = false;
      next();
    };

    routerService.addBeforeEach(onBeforeEach);
    watch(() => profile.value?.kind, onDidProfileUpdated);

    return {
      isMenuHidden,
      isBuildBadgeShown,
      cacheKey,
    };
  },
});
</script>

<style module lang="scss">
.wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

/* NORMALIZE */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.no-scroll {
  height: 100%;
}

.no-scroll body {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg-primary);
  overflow: scroll;
  transform: translate3d(0, 0, 0);
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  white-space: nowrap;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

cite {
  font-style: inherit;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

video::-webkit-media-controls {
  display: none !important;
}
</style>
